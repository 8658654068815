$opensans: 'Open Sans', sans-serif;
$fontawesome: "Font Awesome 5 Pro";
$bjcblue: #00509D;
$darkblue: #012E57;
$lightblue: #F4F6F7;
$newsletterbg: #E5EFF7;

body {
	background: #FFFFFF;
}

body.menu-active {
	overflow-y: hidden;
	overflow-x: hidden;
}

.row {
	margin-bottom: 15px;
}

* {
	font-family: $opensans;

}

a {
	color: $bjcblue;
	transition: .2s;
}

a:visited {
	color: $bjcblue;
}

a:hover {
	color: $darkblue;
	transition: .2s;
}

a:visited, a:active, a:hover {

}

p {
	font-size: 14px;
	color: #707070;
	line-height: 150%;
}

h1 {
	font-family: $opensans;
	font-weight: bold;
	color: $darkblue;
	font-size: 24px;
	line-height: 31px;
	margin-bottom: 20px;
}

h2 {
	font-family: $opensans;
	font-weight: bold;
	color: $darkblue;
	font-size: 20px;
	line-height: 27px;
	margin-bottom: 20px;
}

h3 {
	font-family: $opensans;
	font-weight: bold;
	color: $darkblue;
	font-size: 18px;
	line-height: 25px;
	margin-bottom: 15px;
}


h4 {
	font-family: $opensans;
	font-weight: bold;
	color: $darkblue;
	font-size: 16px;
}

h2.banner {
	font-family: $opensans;
	font-weight: bold;
	color: #FFFFFF;
	font-size: 40px;
	line-height: 108%;
	text-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}

ul, ol {
	margin-bottom: 30px;
	li {
		font-size: 14px;
	}
}

ol {
	margin-left: 0px;

	li {
		margin-bottom: 5px;
	}

	li::marker {
		margin-right: 15px;
	}
}

.full-width-link {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 9;
	cursor: pointer;
}

.content-section {
	padding: 35px 0px;
}

@media screen and (min-width: 768px) {

	p {
		font-size: 16px;
		line-height: 150%;
	}

	h1 {
		font-size: 30px;
		line-height: 33px;
	}

	h2 {
		font-size: 22px;
		line-height: 30px;
	}

	h3 {
		font-size: 20px;
		line-height: 27px;
	}

	ul, ol {
		li {
			font-size: 16px;
			color: #707070;
		}
	}

	.content-section {
		padding: 55px 0px;
	}
}

@media screen and (min-width: 992px) {

	.content-section {
		padding: 75px 0px;
	}
}

hr {
	border: 2px solid $darkblue;
	max-width: 70px;
	width: 100%;
	display: block;
	margin: 0 auto;
	margin-bottom: 20px;
}

.button {
	color: #FFFFFF;
	font-weight: bold;
	font-size: 11px;
	line-height: 15px;
	text-align: center;
	text-decoration: none !important;
	padding: 22px 10px;
	font-size: 16px;
	display: block;
}

.button.dark-blue {
	background: $darkblue;
}

.button.bjc-blue {
	background: $bjcblue;
}

.button:hover {
	color: #FFFFFF;
	opacity: .9;
}

@media screen and (min-width: 768px) {
	.button {
		font-size: 16px;
		padding: 23px 0px;
		margin-left: 0px;
	}
}

.button:active, .button:visited, .button:focus {
	color: #FFFFFF !important;
}

main {
	border: 0px !important;
	margin-bottom: 0px;

	a {
		text-decoration: underline !important;
	}
}



header {
	border-bottom: 0px;
	box-shadow: 0px 4px 4px rgba(28, 56, 78, 0.15);
	position: relative;
	z-index: 100 !important;
}

.covid-update {
	border-top: 5px solid $bjcblue;
	margin-bottom: 10px;

	.covid-content {
		display: none;
		background: $bjcblue;
		padding: 25px 0px 20px 0px;

		p {
			color: #FFFFFF;
			font-size: 14px;
			margin-bottom: 0px;
		}

		.covid-trigger {
			position: absolute;
		    top: 4px;
		    right: 0px;
		    background: transparent;
		    font-size: 20px;
		    cursor: pointer;
		    text-decoration: none !important;
		}
	}

	.covid-trigger {
		background: $bjcblue;
		color: #FFFFFF;
	    font-weight: bold;
	    padding: 4px 10px;
	    text-align: center;
	    text-transform: uppercase;
	    margin: 0 auto;
	    display: block;
	    max-width: 135px;
	    cursor: pointer;
	    text-decoration: none !important;
	}

} /* covid-update */

#mainHeader-inner {
	padding: 0px 15px;
	position: relative;

	#logo {
		padding-left: 0px;
		text-align: center;
		line-height: 0px;

		img {
			width: 235px;
			margin-top: 14px;
		}

	} /* logo */

	.phone-mobile-nav-container {
		display: flex !important;
		float: right;
		margin-top: 14px;

		.phone-container {
			margin-right: 25px;
			i {
				color: $bjcblue;
				font-size: 23px;
			}
		}

		.mobile-menu-toggle-container {
			i {
				color: $bjcblue;
				font-size: 25px;
			}
		}
	} /* phone-mobile-nav-container  */

	.navbar-container {
		display: none;
		padding: 10px 25px;
		padding-top: 55px;

		* {
			color: $bjcblue;
		}

		#close-mobile-menu {
			position: absolute;
			top: 5px;
			right: 15px;

			i {
				font-size: 35px;
			}
		}

		.phone-container {
			border-bottom: 1px solid #E4E4E4;
			padding-bottom: 18px;

			a {
				font-weight: bold;
				font-size: 15px;
				line-height: 20px;
			}

			i {
				margin-right: 8px;
			}
		}

		#dnnMenu {

			.navbar-nav {
				margin: 10px 0px 0px 0px;

				li {
					border-top: 0px;
					padding: 0px;
					background: #FFFFFF;

					a {
						font-size: 15px;
						padding: 0px;
						color: $bjcblue !important;

						span {
							padding: 0px;
							line-height: 40px;
						}
					}

					a:hover {
						background: #FFFFFF !important;
					}
				}

				.navbar-toggle.sub-arrow {
					display: none;
				}
			}

			li.active {
				a {
					background: #FFFFFF;
				}
			}
		}

	} /*navbar-container */

	.navbar-container.active {
		position: absolute;
		top: 0px;
		right: 0px;
		width: 100% !important;
		display: block;
		background: #FFFFFF;
		height: 100vh;
		z-index: 9999;
		overflow-y: hidden;
    	overflow-x: hidden;
	}


} /* mainHeader-inner */

.mainHeader-inner.menu-active.container {
	padding: 0px !important;
}

body.menu-active .covid-update {
	display: none;
}

@media screen and (min-width: 480px) {

	.covid-update {
		margin-bottom: 7px;
	}

	.covid-update .covid-content {
		padding: 8px 0px 20px 0px;
	}

	.covid-update .covid-content p {
		width: 80%;
		float: left;
	}

	.covid-update .close-toggle {
		display: block;
		position: relative;
		float: right;
		width: 20%;
	}

	.covid-update .covid-content .covid-trigger {
		position: relative;
		top: initial;
		right: initial;
		height: 100%;
		font-size: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#mainHeader-inner {
		padding-bottom: 15px;

		#logo a {
			font-size: 14px;
	    	line-height: 17px;
		}

		.phone-mobile-nav-container {
		    margin-top: 15px;

		    .phone-container {
		    	margin-right: 35px;
		    	i {
		    		font-size: 30px;
		    	}
		    }

		    .mobile-menu-toggle-container i {
		    	font-size: 35px;
		    }
		}


	} /* mainHeader-inner */

} /* min-width 480px */

@media screen and (min-width: 768px) {

	.covid-update {

		.covid-trigger {
			padding: 6px 10px;
		    max-width: 172px;
		    font-size: 16px;
		    transition: .2s;
		}

		.covid-trigger:hover {
			padding: 8px 10px;
			transition: .2s;
		}

		.covid-content {

			p {
				width: 90%;
			}

			.close-toggle {
				width: 10%;
			}
		}
	}

	#mainHeader-inner {
		padding-left: 15px;
		padding-right: 15px;

		.phone-mobile-nav-container {
			display: none !important;
		}

		#close-mobile-menu {
			display: none !important;
		}

		#logo img {
			width: 285px;
		}


		#logo a {
		    font-size: 16px;
		    line-height: 16px;
		}

		#logo a:hover {
			color: $bjcblue;
			text-decoration: none !important;
		}

		#logo a:hover hr {
			border: 1px solid $bjcblue;
			border-bottom: 0px;
		}

		.navbar-container {
			display: block;
			position: relative;
			padding: 0px;
    		float: right !important;

    		.phone-container {
				position: absolute;
				top: -12px;
				right: 0px;
				z-index: 9;
				border-bottom: 0px;

				a {
					font-size: 16px;
				}

				a:hover {
					color: $darkblue !important;

					i {
						color: $darkblue !important;
					}
				}
			}

    		 #dnnMenu {

    		 	.navbar-nav {
 	    		    margin: 0px;

 	    		    li {
 	    		    	a {
 	    		    		font-size: 16px;
 	    		    		padding: 22px 20px 0px 20px;
 	    		    	}

 	    		    	a:hover {
 	    		    		color: $darkblue !important;

 	    		    		span {
 	    		    			color: $darkblue !important;
 	    		    		}
 	    		    	}
 	    		    }

 	    		    li:last-of-type a {
 	    		    	padding-right: 0px;
 	    		    }

 	    		    li.active {
 	    		    	border-bottom: 3px solid $bjcblue;

 	    		    	a {
 	    		    		background: transparent;
 	    		    	}
 	    		    }

 	    		    li.dropdown {

 	    		    	.caret {
 	    		    		display: none !important;
 	    		    	}
 	    		    }

 	    		    .dropdown-menu {
 	    		    	display: none !important;
 	    		    }

 	    		} /* navbar-nav */

 	    	} /* dnnMenu */

		} /*navbar-container */

	} /* mainHeader-inner */

} /* min width 768px */

@media screen and (min-width: 992px) {

	.covid-update .covid-content p {
	    width: 95%;
	}

	.covid-update .covid-content .close-toggle {
    	width: 1%;
	}

	#mainHeader-inner {

		#dnnMenu {
			float: right;
		}

		// #logo a {
		// 	font-size: 18px;
		// 	line-height: 18px;
		// }

		 .navbar-container {

		 	.phone-container {
		 		float: right;
		 		padding-bottom: 0px;
		 		position: relative;
		 		top: 0px;
		 		right: initial;
		 		margin-top: 31px;
		 		margin-left: 40px;

		 		a {
		 			text-decoration: none !important;
		 		}
		 	}

		 } /* navbar-container */

	} /* mainHeader inner */

} /* min-width 968px */

@media screen and (min-width: 1200px) {
	.covid-update .covid-content {
		padding: 18px 0px 22px 0px;
	}
}

.sticky-buttons.container {
	position: sticky;
	bottom: 0px;
	background: #FFFFFF;
	z-index: 999;
	border: 1px solid #E4E4E4;
	padding: 10px 15px;

	.left {
		padding-right: 5px;
	}

	.right {
		padding-left: 5px;
	}

	.row {
		margin-bottom: 0px;
	}

	.button {
		display: block;
		font-size: 9px;
		padding: 10px 4px;
	}
}


@media screen and (min-width: 480px) {
	.sticky-buttons.container {

		.button {
			display: block;
			font-size: 13px;
    		padding: 14px 4px;
		}
	}
}

@media screen and (min-width: 600px) {
		.sticky-buttons.container {

			.button {
				font-size: 14px;
				padding: 16px 4px;
			}
		}
}

@media screen and (min-width: 768px) {

	.sticky-buttons.container {
		display: none;
	}
}

/* landing page styling */

.landing-page {

	.video-banner-container {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/mobile-hero.png');
		background-position: top center;
		background-size: cover;
		padding: 85px 15px 90px 15px;
		text-align: center;

		.bg-video-container {
			display: none;
		}

		.desktop-buttons {
			display: none;
		}

		h2 {
			max-width: 400px;
			margin: 0 auto;
			margin-bottom: 35px;
			text-shadow: 0px 4px 4px rgba(0,0,0,0.25);
		}

		p {
			color: #FFFFFF;
			font-size: 18px;
			line-height: 145.8%;
			max-width: 340px;
			margin: 0 auto;
			text-shadow: 0px 4px 4px rgba(0,0,0,0.25);
		}

	} /* video-banner-container */

	.intro-text-container {
		background: #F4F6F7;

		h2 {
			text-align: center;
		}

		.container {
    		max-width: 1020px;
		}

		p:last-of-type {
			margin-bottom: 0px;
		}
	}

	.videos-container {

		.container {
			max-width: 850px;
		}

		h2 {
			text-align: center;
		}

		.video-callout.cta {

			.image-container {
				display: flex;
				align-items: center;
				justify-content: center;
				min-height: 335px;

				i {
					font-size: 100px;
				}

				i:before {
					color: #FFFFFF;
				}

				i:after {
					opacity: .3;
    				color: #FFFFFF;
				}
			}

			.image-container::after {
				min-height: inherit;
				content: "";
			}

			&:hover {
				i:after {
					opacity: .4;
				}
			}

			.image-container.martha {
				background: url("/Portals/0/Skins/Ortho_Campaign/Images/patient-martha-nash.jpg");
				background-size: cover;
				min-height: 335px;
			}

			.image-container.bill {
				background: url("/Portals/0/Skins/Ortho_Campaign/Images/patient-bill-dudley.jpg");
				background-size: cover;
				min-height: 335px;
			}

			.content {
				padding: 20px 20px 30px 20px;

				.hospital {
					color: $bjcblue;
					font-size: 12px;
					text-transform: uppercase;
					margin-bottom: 3px;
				}

				h2 {
					font-size: 22px;
					text-align: left;
					margin-bottom: 10px;
				}

				p {
					margin-bottom: 0px;
				}
			}
		}
	}



	.ctas-container {
		background: $lightblue;

		.row {
			margin-bottom: 0px;
		}	

		h2 {
			text-align: center;
		}
	}

	.bottom-content-container {

		.icon-callout {
			text-align: center;

			.icon-container {
				border: 2px solid $bjcblue;
				border-radius: 40px;
				height: 76px;
				width: 76px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 auto;
				margin-bottom: 20px;
				transition: .2s;

				i {
					color: $bjcblue;
					font-size: 33px;
				}
			}

		} /* icon-callout */

		/* .icon-container:hover {
			background: $bjcblue;
			transition: .2s;

			i {
				color: #FFFFFF;
			}
		} */

	} /* bottom-content */

	.newsletter-signup-container {
		background: $newsletterbg;
		text-align: center;
		overflow: hidden;
		display: block;
		width: 100%;

		p {

		}

		.button.bjc-blue {
			font-size: 14px;
			text-align: center;
			display: block;
			max-width: 235px;
			margin: 0 auto;
			padding: 20px 16px;
			background: #0060B5;
		}
	}

	.newsletter-signup-container.content-section {
		padding: 40px 0px;
	}

} /* landing page */

.request-a-consult {
	background: #E5EFF7;
	
	.container {
		max-height: 320px;
	}

	.icon-container {
		border: 2px solid $bjcblue;
		border-radius: 40px;
		height: 76px;
		width: 76px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		margin-bottom: 20px;
		transition: .2s;

		i {
			color: $bjcblue;
			font-size: 33px;
		}
	}

	.button {
		font-weight: normal;
	}
}

@media screen and (min-width: 600px) {
	.landing-page {

		.video-banner-container {
			padding: 75px 15px;

			h2 {
				font-size: 50px;
				line-height: 103.7%;
				max-width: 575px;
			}

			p {
				font-size: 25px;
				line-height: 27px;
				max-width: 425px;
			}
		}

	} /* landing page */

} /* min-width 600px */

@media screen and (min-width: 768px) {
	.landing-page {
		.video-banner-container {
			padding: 78px 15px;
			background-image: none;
			position: relative;
			overflow: hidden !important;
			margin-top: -2px;
			background-image: url(/Portals/0/Skins/Ortho_Campaign/Images/desktop-hero.png);
			background-size: cover;
			background-position: top center;

			.bg-video-container {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;

				video {
					width: 100%;
				}
			}

			.container {
				z-index: 999;
				position: relative;
				display: block;
			}

			.desktop-buttons {
				display: block;
				max-width: 650px;
				margin: 0 auto;
				margin-top: 50px;

				.button {
					display: block;
					padding: 22px 10px;
					font-size: 16px;
				}
			}

			h2 {
				max-width: 785px;
				font-size: 65px;
				text-shadow: 0px 4px 4px rgba(0,0,0,0.9);
			}

			p {
				max-width: 100%;
				text-shadow: 0px 4px 4px rgba(0,0,0,0.9);
			}

		}

		.intro-text-container {

			h2 {
				text-align: center;
				font-size: 30px;
				margin: 0 auto;
				margin-bottom: 30px;
				max-width: 525px;
				line-height: 35px;
			}
		}

		.videos-container {

			h2 {
				text-align: center;
				font-size: 30px;
				line-height: 35px;
			}
		}

		.ctas-container {

			h2 {
				margin: 0 auto;
				max-width: 375px;
				margin-bottom: 35px;
			}

			.row.last {

				.left {
					.cta {
						float: right;
					}
				}

				.right {
					.cta {
						float: left;
					}
				}

				.cta {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}

		.bottom-content-container {
			.icon-callout {
				
			}
		} /* bottom-content-container */


		.newsletter-signup-container {
			text-align: left;

			.content-section {
				padding: 30px 0px;
			}

			p {
				margin-bottom: 0px;
			}

			.button.bjc-blue {
				width: 100%;
				float: right;
				margin-top: 15px;
				margin-right: 0px;
			}

			.button-container {
				display: flex;
				align-items:center;
				justify-content: center;
			}
		}

	} /* landing page */

	.request-a-consult {

		.button {
			width: 285px;
			margin: 0 auto;
		}
	}
	
} /* min width 768px */

@media screen and (min-width: 992px) {
	.landing-page {
		.video-banner-container {
			padding: 115px 15px;
			background-image: none;

			.bg-video-container  {
				display: block;
			}

			h2 {
				font-size: 70px;
			}

			p {
				font-size: 28px;
			}

		}

		.intro-text-container {
			padding: 50px 0px;
		}

		.videos-container {
			padding: 65px 0px 45px 0px;
		}

		.ctas-container {

			.row.last {

				.cta {
					max-width: 293.33px;
					width: 100%;
					margin: 0 auto;
				}
			}
		}

		.bottom-content-container {
			.icon-callout {
				text-align: left;
			}
		} /* bottom-content-container */

	} /* landing page */

	.request-a-consult {

		.button {
			float: right;
		}
	}
	
} /* min width 992px */



@media screen and (min-width: 1200px) {

	.landing-page {

		.video-banner-container {
			padding: 160px 15px;
		}

		.ctas-container {

			.row.last {

				.cta {
					max-width: 360px;
					width: 100%;
					margin: 0 auto;
				}
			}
		}
	}

} /* min width 1200 */


.cta {
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgba(28,56,78,0.15);
	margin-bottom: 12px;
	position: relative;

	.full-width-link {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 9;
		cursor: pointer;
	}

	.image {
		min-height: 265px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			max-width: 90px;
		}
	}

	.image.virtual-visits {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/virtual-visits.png');
	}

	.image.hip-knee-assessment {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/hip-knee-assessment.png');
	}

	.image.advanced-imaging {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/advanced-imaging.png');
	}

	.image.innovative-technology {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/innovative-technology.jpg');
	}

	.image.like-a-pro {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/like-a-pro.png');
	}

	.image.physical-therapy {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/physical-therapy.png');
	}

	.image.national-leader {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/national-leader.jpg');
	}

	.image.living-well-center {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/living-well-center.png');
	}

	.info-pane {
		padding: 15px 50px 15px 25px;
		position: relative;
		transition: .2s;

		span {
			font-size: 16px;
			color: $bjcblue;
			font-weight: bold;
		}
	}

	.info-pane:after {
		content: '\f30b';
		font-family: $fontawesome;
		color: $bjcblue;
		position: absolute;
		right: 25px;
		top: 16px;
		font-size: 25px;
		transition: .2s;
	}

} /* cta */

.cta:hover {

	.info-pane {
		padding-left: 30px;
		transition: .2s;
	}

	.info-pane:after {
		right: 20px;
		transition: .2s;
	}

	.image {
		opacity: .9;
	}
}


@media screen and (min-width: 768px) {
	.cta {
		margin-bottom: 25px;
		min-height: 282px;
		.image {
			min-height: 195px;
		}

	}

} /* min width 768 */

@media screen and (min-width: 992px) {

	.cta {
		.image {
			min-height: 245px;
		}
	}
}


.modal.in {
	display: flex !important;
	align-items: center;
}

.modal {

	.close {
		opacity: 1;
	}

	.close:after {
		content: '\f00d';
		font-family: $fontawesome;
		color: #FFFFFF;
		position: absolute;
		top: 10px;
		right: 18px;
		font-size: 30px;
		opacity: 1;
	}

	* {
		border-radius: 0px !important;
	}

	.modal-dialog {
		border-radius: 0px !important;
	}

	.modal-content {
		border-radius: 0px !important;

		iframe {
			width: 100%;
			min-height: 350px;
		}

		img {
			width: 100%;
			height: auto;
		}

		.content-container {
			padding: 15px 15px 0px 15px;
		}

		.info-pane {
			padding: 15px;
			position: relative;
			transition: .2s;
			border-top: 1px solid #E4E4E4;

			span {
				font-size: 16px;
				color: $bjcblue;
				font-weight: bold;
			}
		}

	} /* modal-content */

} /* cta-modal */


.sub-page {

	.hero {
		display: flex;
		align-items: center !important;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		min-height: 195px;
		text-align: center;

		.row {
			margin-bottom: 0px;
		}

		h2 {
			text-shadow: 0px 4px 4px rgba(0,0,0,0.25);
			font-size: 40px;
		}

		.desktop-buttons {
			display: none;
		}

	} /* hero */

	.hero::after {
		min-height: inherit;
		content: "";
	}


	.articles-container {
		background: #F4F6F7;

		h2 {
			text-align: center;
		}

		

		.article-cta.symptoms-causes {

			.article-image {
				background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/symptoms-causes-cta.jpg');
			}
		}

		.article-cta.replacement-surgery {

			.article-image {
				background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/replacement-surgery-cta.jpg');
			}
		}

		.article-cta.assessment {

			.article-image {
				background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/washu-physician.png');
			}
		}

	} /* articles-container */
	

	.fad-container {

		.button {
			font-size: 14px;
			text-align: center;
			display: block;
			width: 100%;
			margin: 0 auto;
			padding: 16px;
		}
	}

	.doctor-grid {
		margin-bottom: 30px;

		.col-xs-3 {
			padding: 4px !important;
		}

		.row {
			margin: 0px;
		}

	} /* doctors-grid */

	.guide-container {
		background: $newsletterbg;

		.row {
			margin-bottom: 0px;
		}
		
		.guide-image {
			min-height: 315px;
		    max-width: 300px;
		    margin: 0 auto;
		    margin-top: 40px;
		    background-image: url(/Portals/0/Skins/Ortho_Campaign/Images/guide-preview-desktop.png);
		    background-size: cover;
		    background-repeat: no-repeat;
		    background-position: center;
		    position: relative;
		}

		.content-section {
			padding-left: 15px;
			padding-right: 15px;

			.button {
				font-size: 14px;
				text-align: center;
				display: block;
				width: 100%;
				margin: 0 auto;
				padding: 16px;
			}
		}

	} /* guide-container */

} /* sub-page */

.sub-page.knee {

	.articles-container .article-cta.symptoms-causes .article-image {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/knee-symptoms-causes-cta.jpg')
	}

	.articles-container .article-cta.replacement-surgery .article-image {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/knee-replacement-surgery-cta.jpg')
	}
}

.sub-page.hip .hero {
	background-position: top;
	background-image: url('/Portals/0/Skins/Ortho_Campaign/images/hip-hero.png');
}

@media screen and (min-width: 1200px) {
	.sub-page.hip .hero {
		min-height: 400px;
	}
}


.sub-page.knee .hero {
	background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/knee-hero.jpg');
	background-position: top;
}

@media screen and (min-width: 1200px) {
	.sub-page.knee .hero {
		min-height: 400px;
	}
}

.article-cta {
	box-shadow: 0px 4px 4px rgba(28,56,78,0.15);
	margin-bottom: 10px;
	background: #FFFFFF;
	text-align: left;
	position: relative;

	.article-image {
		min-height: 200px;
		background-size: cover;
		background-repeat: no-repeat; 
		background-position: center center;
	}

	.cta-content {
		padding: 25px 20px;

		h2 {
			text-align: left;
			margin-bottom: 10px;
			font-size: 19px;
		}

		.learn-more {
			color: $bjcblue;
			text-decoration: none !important;
			font-weight: bold;
			font-size: 16px;
			transition: .2s;

			i {
				margin-left: 5px;
			}
		}

		.learn-more:hover {
			padding-left: 6px;
			transition: .2s;
		}

	} /* cta-content */

} /* article-cta */



.article-cta:hover {

	.learn-more {
	    padding-left: 6px;
	    -webkit-transition: .2s;
	    transition: .2s;
	}
}

.article-cta.hip-care {

	.article-image {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/hip-care-cta.png');
	}
}

.article-cta.knee-care {

	.article-image {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/knee-care-cta.jpg');
	}
}

.article-cta.joint-pain-assessment {
	margin-bottom: 50px;

	.article-image {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/joint-pain-assessment-cta.png');
	}
}


.article-cta.hip-pain-symptoms-and-causes {

	.article-image {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/hip-pain-symptoms-and-causes-cta.jpg');
	}
}

.article-cta.hip-replacement-surgery {

	.article-image {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/hip-replacement-surgery-cta.png');
	}
}

.article-cta.knee-pain-symptoms-and-causes {

	.article-image {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/knee-pain-symptoms-causes-cta.png');
	}
}

.article-cta.knee-replacement-surgery {

	.article-image {
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/cta/knee-replacement-surgery-cta.png');
	}
}

@media screen and (min-width: 600px) {

	.sub-page {

		.hero {
			min-height: 275px;
			
			h2 {
				font-size: 55px;
			}
		} /* hero */

	} /* sub-page */

} /* min width 600 */


@media screen and (min-width: 768px) {

	.sub-page {

		.hero {
			min-height: 325px;

			h2 {
				font-size: 70px;
				margin-bottom: 15px;
			}

			.desktop-buttons {
				display: block;
				max-width: 650px;
				margin: 0 auto;
				margin-top: 50px;

				.button {
					display: block;
					padding: 22px 10px;
					font-size: 16px;
				}
			} /* desktop-butons */

		} /* hero */

		.fad-callout {
			display: flex;
			align-items: center;
		}

		.doctors-list {
			float: right;
		}

		.doctor-grid {
			margin-bottom: 0px;

			img:hover {
				opacity: .9;
			}
		}

		.fad-callout {
			float: left;

			.button {
				max-width: 267px;
				font-size: 16px;
				padding: 23px 0px;
				margin-left: 0px;
			}
		}

		.fad-container .container > .row {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
		}

		.guide-container {

			.content-section {
				padding: 17px 15px;

				.button {
					max-width: 267px;
					font-size: 16px;
					padding: 23px 0px;
					margin-left: 0px;
				}
			}

			.guide-image {
				min-height: 365px;
				max-width: 350px;
			}
		}

	} /* sub-page */

} /* min width 768 */

@media screen and (min-width: 992px) {
	.sub-page {

		.articles-container {
			padding: 40px 0px 20px 0px;
		}

		.guide-container {

			.guide-image {
				min-height: 455px;
			    max-width: 500px;
			    margin-top: 0px;
			    background-position-y: 35px;
			}
		} /* guide-container */

	} /* sub-page */

	.sub-page.hip {

		.articles-container {

		}
	}

	.sub-page.knee {

		.articles-container {

		}
	}


} /* min-width 992px */

@media screen and (min-width: 1200px) {

	.sub-page {

		.guide-container {

			.content-section {
			    padding-left: 15px;
			    padding-right: 15px;
			    padding: 56px 15px;
			}
		}
	}

	.sub-page.hip {

		.articles-container {

		}
	}

	.sub-page.knee {

		.articles-container {

		}
	}
}

.sub-page.locations {
	background: #F4F6F7;
	padding-bottom: 80px;

	.hero {
		min-height: 317px;
		margin-bottom: 30px;
		background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/locations-hero.jpg');
		background-position: top;
	}

	.row {
		margin-bottom: 0px;
	}
}

.location-block {
	background: #FFFFFF;
	text-align: center;
	box-shadow: 0px 4px 4px rgba(28, 56, 78, 0.15);
	margin-bottom: 30px;

	.location-content {
		padding: 40px 30px;
	}

	img {
		width: 100%;
		height: auto;
		margin-bottom: 20px;
	}

	h2 {
		font-size: 20px;
		margin-bottom: 10px;
		color: $bjcblue;
	}

	p.address a {
		color: #707070 !important;
		text-decoration: none !important;
	}

	p.sub-header {
		color: $bjcblue;
		font-weight: bold;
		font-size: 15px;
	}

	a.download-map {
		font-weight: bold;
		text-decoration: none !important;
	}

}


.row.single {
	.col-xs-12.col-sm-4 {
		margin: 0 auto;
		float: none;
	}
}

@media screen and (min-width: 768px) {

	.sub-page.locations {

		.hero {

		}
	}

	.location-block {
	}
}

@media screen and (min-width: 992px) {

	.location-block {
	}
}

@media screen and (min-width: 1200px) {

	.sub-page.locations {

		.hero {
			min-height: 380px;
		}
	}
}

/* Article detail page */
.article-detail {
	margin-top: 20px;
	margin-bottom: 30px;

	img {
		width: 100%; 
		height: auto;
	}

	.fad-sidebar-callout {
		background: #F5FBFF;
		box-shadow: 0px 4px 4px rgba(28,56,78,0.15);
		padding: 30px 20px 20px 20px;

		.row {
			display: flex;
			align-items: center;
			margin-bottom: 0px;
		}

		.icon-container {
			border: 2px solid $bjcblue;
			border-radius: 40px;
			height: 76px;
			width: 76px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			margin-bottom: 20px;
			transition: .2s;

			i {
				color: $bjcblue;
				font-size: 33px;
			}
		}

		.text-container {
		}

		.button {
			display: block; 
			width:100%;
			max-width: 100%;
		}

	} /* fad sidebar callout */

	.form-container {
		background: $newsletterbg;
		box-shadow: 0px 4px 4px rgba(28,56,78,0.15);
		padding: 20px;

		h2 {
			font-size: 20px;
			text-align: center;
			margin-bottom: 20px;
		}

	} /* form-container */

} /* article detail */

@media screen and (min-width: 768px) {

	.article-detail {
		margin-top: 30px;

		h2 {
			font-size: 30px;
		}

		.fad-sidebar-callout {
			text-align: center;
			margin-bottom: 20px;

			.row {
				display: block;
			}

			h2 {
				font-size: 20px;
			}
		}
	}
}

@media screen and (min-width: 1200px) {

	.article-detail {
		
		h2 {
			font-size: 30px;
		}

		.fad-sidebar-callout {
			text-align: left;

			.row {
				display: flex;
				align-items: center;
			}

			h2 {
				font-size: 20px;
				padding-left: 10px;
				padding-right: 8px;
			}
		}
	}
}

.make-an-appointment {
	margin-top: 60px;

	.top-content-container {
		max-width: 615px;
		margin: 0 auto;
	}

	.maa-text {
		text-align: center;
		font-size: 18px;
		line-height: 27px;

		a {
			text-decoration: none !important;
		}
	}

	.maa-text.large {
		font-size: 25px;
		line-height: 34px;
		margin-bottom: 50px;
	}
}

.form-container-large {
	padding: 30px 20px;
	background: $newsletterbg;
	max-width: 900px;
	margin: 0 auto;
	margin-top: 50px;
	box-shadow: 0px 4px 4px rgba(28,56,78,0.15);
	margin-bottom: 80px;

	h2 {
		text-align: center;
		font-size: 30px;
		line-height: 105%;
		margin-bottom: 30px;
	}

	hr {
		margin-bottom: 50px;
	}
}

@media screen and (min-width: 768px) {

	.make-an-appointment {
	}

	.form-container-large {
		padding: 50px 40px;
	}
}

.free-guide {

	.container.form {
		z-index: 99;
		position: relative;
		margin-top: -150px;
	}

	.top-content-container {
		padding-top: 50px;
		background: $lightblue;
		text-align: center;

		p {
			font-size: 14px;
			color: #707070;
			line-height: 150%;
			font-weight: bold;
		}

		p.hide-mobile {
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			display: none;
		}

		img {
			z-index: 1;
		}
	}

	.form-container-large {
		margin-top: 0px;
		min-height: 500px;

		.content-container {
		    height: 100%;
		    width: 100%
		}

		h2 {
			text-align: center;
		}
		
	}
}


@media screen and (min-width: 768px) {
	.free-guide {

		.container.form {
			margin-top: -75px;
		}

		.top-content-container {
			text-align: left;
			padding-bottom: 90px;

			.row {
				display: flex;
			    align-items: center;
			    flex-direction: row-reverse;
			}

			.container {
				max-width: 900px;
				margin: 0 auto;
			}

			.guide-pic {
				float: left !important;
			}

			.header-content {
				float: right !important;
			}

			h2 {
				font-size: 29px;
			}

			p {
				font-size: 16px;
			}

			p.hide-mobile {
				display: block;
			}

		}

		.form-container-large {

			.content-container {
				width: 100%;
			}
		}
	}
}

@media screen and (min-width: 992px) {
	.free-guide {

		.form-container-large {

			.content-container {
				width: 100%;
			}
		}

	}
}

@media screen and (min-width: 1200px) {
	.free-guide {

		.container.form {
    		margin-top: -105px;
		}

		.top-content-container {
			text-align: left;
			padding-bottom: 120px;
		}

		.form-container-large {

			.content-container {
				width: 100%;
			}
		}

	}
}

/* Form styling */
main.sub-page,
main.landing-page,
#form {

	label.control-label {
		font-size: 15px !important;
		color: $darkblue !important;
		margin-bottom: 5px !important;
		font-weight: bold !important;
	}

	label.control-label.required::after {
		content: "*" !important;
		color: #D70000  !important;
		padding-left: 2px  !important;
	}

	input, textarea, select {
		border: 1px solid rgba(102, 143, 183,.23) !important;
		background: #FFFFFF !important;
		border-radius: 0px !important;
		box-shadow: none !important;
	}

	input, select {
		height: 45px !important;
		padding: 10px;
	}

	input:active,
	input:focus,
	textarea:active,
	textarea:focus,
	select:active,
	select:focus {
		background: rgba(255,255,255,.85) !important;
		border: 1px solid rgba(0, 80, 157,.3) !important;
		box-shadow: none !important;
	}

	select {
	  /* for Firefox */
	  -moz-appearance: none !important;
	  /* for Chrome */
	  -webkit-appearance: none !important;
	  background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/dropdown-arrow.png') !important;
	  background-position-x: calc(100% - 20px) !important;
	  background-position-y: 19px !important;
	  background-repeat: no-repeat !important;
	  padding-right: 38px;
	}

	select:active,
	select:focus {
		 /* for Firefox */
		 -moz-appearance: none !important;
		 /* for Chrome */
		 -webkit-appearance: none !important;
		 background-image: url('/Portals/0/Skins/Ortho_Campaign/Images/dropdown-arrow.png') !important;
		 background-position-x: calc(100% - 20px) !important;
		 background-position-y: 19px !important;
		 background-repeat: no-repeat !important;
	}

	/* For IE10 */
	select::-ms-expand {
	  display: none  !important;
	}

	button.button {
		text-align: center  !important;
		color: #FFFFFF !important;
		background: $bjcblue !important;
		padding: 20px 20px !important;
		font-size: 16px !important;
		transition: .2s !important;
		display: block !important;
		max-width: 415px !important;
		margin: 0 auto !important;
		min-width: 315px !important;
		border: 0px !important;
		border-radius: 0px !important;
		text-transform: none;
	}

	button.button:hover {
		opacity: .9 !important;
		transition: .2s !important;
	}

	button.button.sidebar {
		min-width: 1px !important;
		width: 100%;
	}

	.dropdown {
		margin-top: 36px !important;
	}
}

.alert.alert-info.submit-confirm {
	background: transparent !important;
	box-shadow: none !important;

	h1, h2, h3, h4 {
		color: $darkblue !important;
	}

	a {
		color: $darkblue !important;
	}

	a:hover {
		color: $bjcblue !important;
	}
}

footer {
	text-align: center;

	* {
		color: #FFFFFF;
	}

	p {
		margin-bottom: 0px;
	}

	.row {
		margin-bottom: 0px;
	}

	.top-row {
		background: $bjcblue;
		padding-top: 25px;
		padding-bottom: 25px;

		.logo-copyright {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 25px;
			font-family: $opensans;
			max-width: 326px;
			margin: 0 auto;
			margin-bottom: 20px;
			text-align: left;

			img {
				width: 220px;
				text-align: left;
			}

			hr {
				border: 1px solid #FFFFFF;
				border-bottom: 0px;
				margin-top: 7px;
				margin-bottom: 7px;
				max-width: 56%;
				width: 56%;
			}

			a {
				font-size: 16px;
				text-transform: uppercase;
			}

			a:visited, a:active {
				color: #FFFFFF;
			}

			a:hover,
			a:hover hr {
				opacity: .8;
				color: #FFFFFF;
				text-decoration: none !important;
			}

		}

		.footer-navigation {
			margin-top: 10px;

			a {
				font-size: 16px;
				line-height: 22px;
				font-family: $opensans;
				margin-bottom: 20px;
				display: block;
				text-decoration: none !important;
			}

			a:visited, a:active, a:focus {
				color: #FFFFFF;
			}

			a:hover {
				color: #FFFFFF;
				opacity: .8;
			}
		}
	}

	.bottom-row {
		background: $darkblue;
		padding: 20px 0px;

		p {
			font-size: 12px;
			line-height: 150%;

			a {
				color: #FFFFFF !important;
				text-decoration: none !important;
			}
		}
	}

} /* footer */

@media screen and (min-width: 768px) {

	footer {

		.top-row {

			.logo-copyright {
				margin-left: 0px;
				margin-top: 20px;
				margin-bottom: 0px;

				p {
					text-align: left;
				}
			}

			.footer-navigation {
				text-align: left;
			}

		} /* top-row */

		.bottom-row {

			p {
				br {
					display: none;
				}
			}
		}

	} /* footer */

} /* min width 768px */

.flex-container .logoimage {
    flex-shrink: 0;
    max-height: 120px;
    margin-top: 20px;
    max-width: 180px;
}

/* locations */

.location {
	padding: 30px 25px 10px 25px;
	background: #FFFFFF;
	max-width: 746px;
	margin: 0 auto;
	margin-bottom: 25px;

	.location-title {
		margin-bottom: 10px;
	}

	.secondary-title {
		text-transform: uppercase;
		font-size: 12px;
		color: #1C384E;
	}

	hr {
		margin-left: 0px;
		margin-top: 20px;
		margin-bottom: 20px;
		border: 0px;
    	height: 2px;
    	background: #012e57;
	}

	p {
		color: #707070;
		line-height: 30px;
	}

}

@media screen and (min-width: 768px) {

}