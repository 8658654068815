body {
  background: #FFFFFF; }

body.menu-active {
  overflow-y: hidden;
  overflow-x: hidden; }

.row {
  margin-bottom: 15px; }

* {
  font-family: "Open Sans", sans-serif; }

a {
  color: #00509D;
  transition: .2s; }

a:visited {
  color: #00509D; }

a:hover {
  color: #012E57;
  transition: .2s; }

p {
  font-size: 14px;
  color: #707070;
  line-height: 150%; }

h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #012E57;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 20px; }

h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #012E57;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 20px; }

h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #012E57;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 15px; }

h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #012E57;
  font-size: 16px; }

h2.banner {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #FFFFFF;
  font-size: 40px;
  line-height: 108%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

ul, ol {
  margin-bottom: 30px; }

ul li, ol li {
  font-size: 14px; }

ol {
  margin-left: 0px; }

ol li {
  margin-bottom: 5px; }

ol li::marker {
  margin-right: 15px; }

.full-width-link {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: pointer; }

.content-section {
  padding: 35px 0px; }

@media screen and (min-width: 768px) {
  p {
    font-size: 16px;
    line-height: 150%; }
  h1 {
    font-size: 30px;
    line-height: 33px; }
  h2 {
    font-size: 22px;
    line-height: 30px; }
  h3 {
    font-size: 20px;
    line-height: 27px; }
  ul li, ol li {
    font-size: 16px;
    color: #707070; }
  .content-section {
    padding: 55px 0px; } }

@media screen and (min-width: 992px) {
  .content-section {
    padding: 75px 0px; } }

hr {
  border: 2px solid #012E57;
  max-width: 70px;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px; }

.button {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  text-decoration: none !important;
  padding: 22px 10px;
  font-size: 16px;
  display: block; }

.button.dark-blue {
  background: #012E57; }

.button.bjc-blue {
  background: #00509D; }

.button:hover {
  color: #FFFFFF;
  opacity: .9; }

@media screen and (min-width: 768px) {
  .button {
    font-size: 16px;
    padding: 23px 0px;
    margin-left: 0px; } }

.button:active, .button:visited, .button:focus {
  color: #FFFFFF !important; }

main {
  border: 0px !important;
  margin-bottom: 0px; }

main a {
  text-decoration: underline !important; }

header {
  border-bottom: 0px;
  box-shadow: 0px 4px 4px rgba(28, 56, 78, 0.15);
  position: relative;
  z-index: 100 !important; }

.covid-update {
  border-top: 5px solid #00509D;
  margin-bottom: 10px; }

.covid-update .covid-content {
  display: none;
  background: #00509D;
  padding: 25px 0px 20px 0px; }

.covid-update .covid-content p {
  color: #FFFFFF;
  font-size: 14px;
  margin-bottom: 0px; }

.covid-update .covid-content .covid-trigger {
  position: absolute;
  top: 4px;
  right: 0px;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none !important; }

.covid-update .covid-trigger {
  background: #00509D;
  color: #FFFFFF;
  font-weight: bold;
  padding: 4px 10px;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
  display: block;
  max-width: 135px;
  cursor: pointer;
  text-decoration: none !important; }

/* covid-update */
#mainHeader-inner {
  padding: 0px 15px;
  position: relative;
  /* logo */
  /* phone-mobile-nav-container  */
  /*navbar-container */ }

#mainHeader-inner #logo {
  padding-left: 0px;
  text-align: center;
  line-height: 0px; }

#mainHeader-inner #logo img {
  width: 235px;
  margin-top: 14px; }

#mainHeader-inner .phone-mobile-nav-container {
  display: flex !important;
  float: right;
  margin-top: 14px; }

#mainHeader-inner .phone-mobile-nav-container .phone-container {
  margin-right: 25px; }

#mainHeader-inner .phone-mobile-nav-container .phone-container i {
  color: #00509D;
  font-size: 23px; }

#mainHeader-inner .phone-mobile-nav-container .mobile-menu-toggle-container i {
  color: #00509D;
  font-size: 25px; }

#mainHeader-inner .navbar-container {
  display: none;
  padding: 10px 25px;
  padding-top: 55px; }

#mainHeader-inner .navbar-container * {
  color: #00509D; }

#mainHeader-inner .navbar-container #close-mobile-menu {
  position: absolute;
  top: 5px;
  right: 15px; }

#mainHeader-inner .navbar-container #close-mobile-menu i {
  font-size: 35px; }

#mainHeader-inner .navbar-container .phone-container {
  border-bottom: 1px solid #E4E4E4;
  padding-bottom: 18px; }

#mainHeader-inner .navbar-container .phone-container a {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px; }

#mainHeader-inner .navbar-container .phone-container i {
  margin-right: 8px; }

#mainHeader-inner .navbar-container #dnnMenu .navbar-nav {
  margin: 10px 0px 0px 0px; }

#mainHeader-inner .navbar-container #dnnMenu .navbar-nav li {
  border-top: 0px;
  padding: 0px;
  background: #FFFFFF; }

#mainHeader-inner .navbar-container #dnnMenu .navbar-nav li a {
  font-size: 15px;
  padding: 0px;
  color: #00509D !important; }

#mainHeader-inner .navbar-container #dnnMenu .navbar-nav li a span {
  padding: 0px;
  line-height: 40px; }

#mainHeader-inner .navbar-container #dnnMenu .navbar-nav li a:hover {
  background: #FFFFFF !important; }

#mainHeader-inner .navbar-container #dnnMenu .navbar-nav .navbar-toggle.sub-arrow {
  display: none; }

#mainHeader-inner .navbar-container #dnnMenu li.active a {
  background: #FFFFFF; }

#mainHeader-inner .navbar-container.active {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100% !important;
  display: block;
  background: #FFFFFF;
  height: 100vh;
  z-index: 9999;
  overflow-y: hidden;
  overflow-x: hidden; }

/* mainHeader-inner */
.mainHeader-inner.menu-active.container {
  padding: 0px !important; }

body.menu-active .covid-update {
  display: none; }

@media screen and (min-width: 480px) {
  .covid-update {
    margin-bottom: 7px; }
  .covid-update .covid-content {
    padding: 8px 0px 20px 0px; }
  .covid-update .covid-content p {
    width: 80%;
    float: left; }
  .covid-update .close-toggle {
    display: block;
    position: relative;
    float: right;
    width: 20%; }
  .covid-update .covid-content .covid-trigger {
    position: relative;
    top: initial;
    right: initial;
    height: 100%;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center; }
  #mainHeader-inner {
    padding-bottom: 15px; }
  #mainHeader-inner #logo a {
    font-size: 14px;
    line-height: 17px; }
  #mainHeader-inner .phone-mobile-nav-container {
    margin-top: 15px; }
  #mainHeader-inner .phone-mobile-nav-container .phone-container {
    margin-right: 35px; }
  #mainHeader-inner .phone-mobile-nav-container .phone-container i {
    font-size: 30px; }
  #mainHeader-inner .phone-mobile-nav-container .mobile-menu-toggle-container i {
    font-size: 35px; }
  /* mainHeader-inner */ }

/* min-width 480px */
@media screen and (min-width: 768px) {
  .covid-update .covid-trigger {
    padding: 6px 10px;
    max-width: 172px;
    font-size: 16px;
    transition: .2s; }
  .covid-update .covid-trigger:hover {
    padding: 8px 10px;
    transition: .2s; }
  .covid-update .covid-content p {
    width: 90%; }
  .covid-update .covid-content .close-toggle {
    width: 10%; }
  #mainHeader-inner {
    padding-left: 15px;
    padding-right: 15px;
    /*navbar-container */ }
  #mainHeader-inner .phone-mobile-nav-container {
    display: none !important; }
  #mainHeader-inner #close-mobile-menu {
    display: none !important; }
  #mainHeader-inner #logo img {
    width: 285px; }
  #mainHeader-inner #logo a {
    font-size: 16px;
    line-height: 16px; }
  #mainHeader-inner #logo a:hover {
    color: #00509D;
    text-decoration: none !important; }
  #mainHeader-inner #logo a:hover hr {
    border: 1px solid #00509D;
    border-bottom: 0px; }
  #mainHeader-inner .navbar-container {
    display: block;
    position: relative;
    padding: 0px;
    float: right !important;
    /* dnnMenu */ }
  #mainHeader-inner .navbar-container .phone-container {
    position: absolute;
    top: -12px;
    right: 0px;
    z-index: 9;
    border-bottom: 0px; }
  #mainHeader-inner .navbar-container .phone-container a {
    font-size: 16px; }
  #mainHeader-inner .navbar-container .phone-container a:hover {
    color: #012E57 !important; }
  #mainHeader-inner .navbar-container .phone-container a:hover i {
    color: #012E57 !important; }
  #mainHeader-inner .navbar-container #dnnMenu {
    /* navbar-nav */ }
  #mainHeader-inner .navbar-container #dnnMenu .navbar-nav {
    margin: 0px; }
  #mainHeader-inner .navbar-container #dnnMenu .navbar-nav li a {
    font-size: 16px;
    padding: 22px 20px 0px 20px; }
  #mainHeader-inner .navbar-container #dnnMenu .navbar-nav li a:hover {
    color: #012E57 !important; }
  #mainHeader-inner .navbar-container #dnnMenu .navbar-nav li a:hover span {
    color: #012E57 !important; }
  #mainHeader-inner .navbar-container #dnnMenu .navbar-nav li:last-of-type a {
    padding-right: 0px; }
  #mainHeader-inner .navbar-container #dnnMenu .navbar-nav li.active {
    border-bottom: 3px solid #00509D; }
  #mainHeader-inner .navbar-container #dnnMenu .navbar-nav li.active a {
    background: transparent; }
  #mainHeader-inner .navbar-container #dnnMenu .navbar-nav li.dropdown .caret {
    display: none !important; }
  #mainHeader-inner .navbar-container #dnnMenu .navbar-nav .dropdown-menu {
    display: none !important; }
  /* mainHeader-inner */ }

/* min width 768px */
@media screen and (min-width: 992px) {
  .covid-update .covid-content p {
    width: 95%; }
  .covid-update .covid-content .close-toggle {
    width: 1%; }
  #mainHeader-inner {
    /* navbar-container */ }
  #mainHeader-inner #dnnMenu {
    float: right; }
  #mainHeader-inner .navbar-container .phone-container {
    float: right;
    padding-bottom: 0px;
    position: relative;
    top: 0px;
    right: initial;
    margin-top: 31px;
    margin-left: 40px; }
  #mainHeader-inner .navbar-container .phone-container a {
    text-decoration: none !important; }
  /* mainHeader inner */ }

/* min-width 968px */
@media screen and (min-width: 1200px) {
  .covid-update .covid-content {
    padding: 18px 0px 22px 0px; } }

.sticky-buttons.container {
  position: sticky;
  bottom: 0px;
  background: #FFFFFF;
  z-index: 999;
  border: 1px solid #E4E4E4;
  padding: 10px 15px; }

.sticky-buttons.container .left {
  padding-right: 5px; }

.sticky-buttons.container .right {
  padding-left: 5px; }

.sticky-buttons.container .row {
  margin-bottom: 0px; }

.sticky-buttons.container .button {
  display: block;
  font-size: 9px;
  padding: 10px 4px; }

@media screen and (min-width: 480px) {
  .sticky-buttons.container .button {
    display: block;
    font-size: 13px;
    padding: 14px 4px; } }

@media screen and (min-width: 600px) {
  .sticky-buttons.container .button {
    font-size: 14px;
    padding: 16px 4px; } }

@media screen and (min-width: 768px) {
  .sticky-buttons.container {
    display: none; } }

/* landing page styling */
.landing-page {
  /* video-banner-container */
  /* bottom-content */ }

.landing-page .video-banner-container {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/mobile-hero.png");
  background-position: top center;
  background-size: cover;
  padding: 85px 15px 90px 15px;
  text-align: center; }

.landing-page .video-banner-container .bg-video-container {
  display: none; }

.landing-page .video-banner-container .desktop-buttons {
  display: none; }

.landing-page .video-banner-container h2 {
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 35px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.landing-page .video-banner-container p {
  color: #FFFFFF;
  font-size: 18px;
  line-height: 145.8%;
  max-width: 340px;
  margin: 0 auto;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.landing-page .intro-text-container {
  background: #F4F6F7; }

.landing-page .intro-text-container h2 {
  text-align: center; }

.landing-page .intro-text-container .container {
  max-width: 1020px; }

.landing-page .intro-text-container p:last-of-type {
  margin-bottom: 0px; }

.landing-page .videos-container .container {
  max-width: 850px; }

.landing-page .videos-container h2 {
  text-align: center; }

.landing-page .videos-container .video-callout.cta .image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 335px; }

.landing-page .videos-container .video-callout.cta .image-container i {
  font-size: 100px; }

.landing-page .videos-container .video-callout.cta .image-container i:before {
  color: #FFFFFF; }

.landing-page .videos-container .video-callout.cta .image-container i:after {
  opacity: .3;
  color: #FFFFFF; }

.landing-page .videos-container .video-callout.cta .image-container::after {
  min-height: inherit;
  content: ""; }

.landing-page .videos-container .video-callout.cta:hover i:after {
  opacity: .4; }

.landing-page .videos-container .video-callout.cta .image-container.martha {
  background: url("/Portals/0/Skins/Ortho_Campaign/Images/patient-martha-nash.jpg");
  background-size: cover;
  min-height: 335px; }

.landing-page .videos-container .video-callout.cta .image-container.bill {
  background: url("/Portals/0/Skins/Ortho_Campaign/Images/patient-bill-dudley.jpg");
  background-size: cover;
  min-height: 335px; }

.landing-page .videos-container .video-callout.cta .content {
  padding: 20px 20px 30px 20px; }

.landing-page .videos-container .video-callout.cta .content .hospital {
  color: #00509D;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 3px; }

.landing-page .videos-container .video-callout.cta .content h2 {
  font-size: 22px;
  text-align: left;
  margin-bottom: 10px; }

.landing-page .videos-container .video-callout.cta .content p {
  margin-bottom: 0px; }

.landing-page .ctas-container {
  background: #F4F6F7; }

.landing-page .ctas-container .row {
  margin-bottom: 0px; }

.landing-page .ctas-container h2 {
  text-align: center; }

.landing-page .bottom-content-container {
  /* icon-callout */
  /* .icon-container:hover {
			background: $bjcblue;
			transition: .2s;

			i {
				color: #FFFFFF;
			}
		} */ }

.landing-page .bottom-content-container .icon-callout {
  text-align: center; }

.landing-page .bottom-content-container .icon-callout .icon-container {
  border: 2px solid #00509D;
  border-radius: 40px;
  height: 76px;
  width: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  transition: .2s; }

.landing-page .bottom-content-container .icon-callout .icon-container i {
  color: #00509D;
  font-size: 33px; }

.landing-page .newsletter-signup-container {
  background: #E5EFF7;
  text-align: center;
  overflow: hidden;
  display: block;
  width: 100%; }

.landing-page .newsletter-signup-container .button.bjc-blue {
  font-size: 14px;
  text-align: center;
  display: block;
  max-width: 235px;
  margin: 0 auto;
  padding: 20px 16px;
  background: #0060B5; }

.landing-page .newsletter-signup-container.content-section {
  padding: 40px 0px; }

/* landing page */
.request-a-consult {
  background: #E5EFF7; }

.request-a-consult .container {
  max-height: 320px; }

.request-a-consult .icon-container {
  border: 2px solid #00509D;
  border-radius: 40px;
  height: 76px;
  width: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  transition: .2s; }

.request-a-consult .icon-container i {
  color: #00509D;
  font-size: 33px; }

.request-a-consult .button {
  font-weight: normal; }

@media screen and (min-width: 600px) {
  .landing-page .video-banner-container {
    padding: 75px 15px; }
  .landing-page .video-banner-container h2 {
    font-size: 50px;
    line-height: 103.7%;
    max-width: 575px; }
  .landing-page .video-banner-container p {
    font-size: 25px;
    line-height: 27px;
    max-width: 425px; }
  /* landing page */ }

/* min-width 600px */
@media screen and (min-width: 768px) {
  .landing-page {
    /* bottom-content-container */ }
  .landing-page .video-banner-container {
    padding: 78px 15px;
    background-image: none;
    position: relative;
    overflow: hidden !important;
    margin-top: -2px;
    background-image: url(/Portals/0/Skins/Ortho_Campaign/Images/desktop-hero.png);
    background-size: cover;
    background-position: top center; }
  .landing-page .video-banner-container .bg-video-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%; }
  .landing-page .video-banner-container .bg-video-container video {
    width: 100%; }
  .landing-page .video-banner-container .container {
    z-index: 999;
    position: relative;
    display: block; }
  .landing-page .video-banner-container .desktop-buttons {
    display: block;
    max-width: 650px;
    margin: 0 auto;
    margin-top: 50px; }
  .landing-page .video-banner-container .desktop-buttons .button {
    display: block;
    padding: 22px 10px;
    font-size: 16px; }
  .landing-page .video-banner-container h2 {
    max-width: 785px;
    font-size: 65px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.9); }
  .landing-page .video-banner-container p {
    max-width: 100%;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.9); }
  .landing-page .intro-text-container h2 {
    text-align: center;
    font-size: 30px;
    margin: 0 auto;
    margin-bottom: 30px;
    max-width: 525px;
    line-height: 35px; }
  .landing-page .videos-container h2 {
    text-align: center;
    font-size: 30px;
    line-height: 35px; }
  .landing-page .ctas-container h2 {
    margin: 0 auto;
    max-width: 375px;
    margin-bottom: 35px; }
  .landing-page .ctas-container .row.last .left .cta {
    float: right; }
  .landing-page .ctas-container .row.last .right .cta {
    float: left; }
  .landing-page .ctas-container .row.last .cta {
    max-width: 220px;
    margin: 0 auto; }
  .landing-page .newsletter-signup-container {
    text-align: left; }
  .landing-page .newsletter-signup-container .content-section {
    padding: 30px 0px; }
  .landing-page .newsletter-signup-container p {
    margin-bottom: 0px; }
  .landing-page .newsletter-signup-container .button.bjc-blue {
    width: 100%;
    float: right;
    margin-top: 15px;
    margin-right: 0px; }
  .landing-page .newsletter-signup-container .button-container {
    display: flex;
    align-items: center;
    justify-content: center; }
  /* landing page */
  .request-a-consult .button {
    width: 285px;
    margin: 0 auto; } }

/* min width 768px */
@media screen and (min-width: 992px) {
  .landing-page {
    /* bottom-content-container */ }
  .landing-page .video-banner-container {
    padding: 115px 15px;
    background-image: none; }
  .landing-page .video-banner-container .bg-video-container {
    display: block; }
  .landing-page .video-banner-container h2 {
    font-size: 70px; }
  .landing-page .video-banner-container p {
    font-size: 28px; }
  .landing-page .intro-text-container {
    padding: 50px 0px; }
  .landing-page .videos-container {
    padding: 65px 0px 45px 0px; }
  .landing-page .ctas-container .row.last .cta {
    max-width: 293.33px;
    width: 100%;
    margin: 0 auto; }
  .landing-page .bottom-content-container .icon-callout {
    text-align: left; }
  /* landing page */
  .request-a-consult .button {
    float: right; } }

/* min width 992px */
@media screen and (min-width: 1200px) {
  .landing-page .video-banner-container {
    padding: 160px 15px; }
  .landing-page .ctas-container .row.last .cta {
    max-width: 360px;
    width: 100%;
    margin: 0 auto; } }

/* min width 1200 */
.cta {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(28, 56, 78, 0.15);
  margin-bottom: 12px;
  position: relative; }

.cta .full-width-link {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: pointer; }

.cta .image {
  min-height: 265px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center; }

.cta .image img {
  max-width: 90px; }

.cta .image.virtual-visits {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/virtual-visits.png"); }

.cta .image.hip-knee-assessment {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/hip-knee-assessment.png"); }

.cta .image.advanced-imaging {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/advanced-imaging.png"); }

.cta .image.innovative-technology {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/innovative-technology.jpg"); }

.cta .image.like-a-pro {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/like-a-pro.png"); }

.cta .image.physical-therapy {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/physical-therapy.png"); }

.cta .image.national-leader {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/national-leader.jpg"); }

.cta .image.living-well-center {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/living-well-center.png"); }

.cta .info-pane {
  padding: 15px 50px 15px 25px;
  position: relative;
  transition: .2s; }

.cta .info-pane span {
  font-size: 16px;
  color: #00509D;
  font-weight: bold; }

.cta .info-pane:after {
  content: '\f30b';
  font-family: "Font Awesome 5 Pro";
  color: #00509D;
  position: absolute;
  right: 25px;
  top: 16px;
  font-size: 25px;
  transition: .2s; }

/* cta */
.cta:hover .info-pane {
  padding-left: 30px;
  transition: .2s; }

.cta:hover .info-pane:after {
  right: 20px;
  transition: .2s; }

.cta:hover .image {
  opacity: .9; }

@media screen and (min-width: 768px) {
  .cta {
    margin-bottom: 25px;
    min-height: 282px; }
  .cta .image {
    min-height: 195px; } }

/* min width 768 */
@media screen and (min-width: 992px) {
  .cta .image {
    min-height: 245px; } }

.modal.in {
  display: flex !important;
  align-items: center; }

.modal {
  /* modal-content */ }

.modal .close {
  opacity: 1; }

.modal .close:after {
  content: '\f00d';
  font-family: "Font Awesome 5 Pro";
  color: #FFFFFF;
  position: absolute;
  top: 10px;
  right: 18px;
  font-size: 30px;
  opacity: 1; }

.modal * {
  border-radius: 0px !important; }

.modal .modal-dialog {
  border-radius: 0px !important; }

.modal .modal-content {
  border-radius: 0px !important; }

.modal .modal-content iframe {
  width: 100%;
  min-height: 350px; }

.modal .modal-content img {
  width: 100%;
  height: auto; }

.modal .modal-content .content-container {
  padding: 15px 15px 0px 15px; }

.modal .modal-content .info-pane {
  padding: 15px;
  position: relative;
  transition: .2s;
  border-top: 1px solid #E4E4E4; }

.modal .modal-content .info-pane span {
  font-size: 16px;
  color: #00509D;
  font-weight: bold; }

/* cta-modal */
.sub-page {
  /* hero */
  /* articles-container */
  /* doctors-grid */
  /* guide-container */ }

.sub-page .hero {
  display: flex;
  align-items: center !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 195px;
  text-align: center; }

.sub-page .hero .row {
  margin-bottom: 0px; }

.sub-page .hero h2 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 40px; }

.sub-page .hero .desktop-buttons {
  display: none; }

.sub-page .hero::after {
  min-height: inherit;
  content: ""; }

.sub-page .articles-container {
  background: #F4F6F7; }

.sub-page .articles-container h2 {
  text-align: center; }

.sub-page .articles-container .article-cta.symptoms-causes .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/symptoms-causes-cta.jpg"); }

.sub-page .articles-container .article-cta.replacement-surgery .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/replacement-surgery-cta.jpg"); }

.sub-page .articles-container .article-cta.assessment .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/washu-physician.png"); }

.sub-page .fad-container .button {
  font-size: 14px;
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 16px; }

.sub-page .doctor-grid {
  margin-bottom: 30px; }

.sub-page .doctor-grid .col-xs-3 {
  padding: 4px !important; }

.sub-page .doctor-grid .row {
  margin: 0px; }

.sub-page .guide-container {
  background: #E5EFF7; }

.sub-page .guide-container .row {
  margin-bottom: 0px; }

.sub-page .guide-container .guide-image {
  min-height: 315px;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 40px;
  background-image: url(/Portals/0/Skins/Ortho_Campaign/Images/guide-preview-desktop.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative; }

.sub-page .guide-container .content-section {
  padding-left: 15px;
  padding-right: 15px; }

.sub-page .guide-container .content-section .button {
  font-size: 14px;
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 16px; }

/* sub-page */
.sub-page.knee .articles-container .article-cta.symptoms-causes .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/knee-symptoms-causes-cta.jpg"); }

.sub-page.knee .articles-container .article-cta.replacement-surgery .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/knee-replacement-surgery-cta.jpg"); }

.sub-page.hip .hero {
  background-position: top;
  background-image: url("/Portals/0/Skins/Ortho_Campaign/images/hip-hero.png"); }

@media screen and (min-width: 1200px) {
  .sub-page.hip .hero {
    min-height: 400px; } }

.sub-page.knee .hero {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/knee-hero.jpg");
  background-position: top; }

@media screen and (min-width: 1200px) {
  .sub-page.knee .hero {
    min-height: 400px; } }

.article-cta {
  box-shadow: 0px 4px 4px rgba(28, 56, 78, 0.15);
  margin-bottom: 10px;
  background: #FFFFFF;
  text-align: left;
  position: relative;
  /* cta-content */ }

.article-cta .article-image {
  min-height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.article-cta .cta-content {
  padding: 25px 20px; }

.article-cta .cta-content h2 {
  text-align: left;
  margin-bottom: 10px;
  font-size: 19px; }

.article-cta .cta-content .learn-more {
  color: #00509D;
  text-decoration: none !important;
  font-weight: bold;
  font-size: 16px;
  transition: .2s; }

.article-cta .cta-content .learn-more i {
  margin-left: 5px; }

.article-cta .cta-content .learn-more:hover {
  padding-left: 6px;
  transition: .2s; }

/* article-cta */
.article-cta:hover .learn-more {
  padding-left: 6px;
  -webkit-transition: .2s;
  transition: .2s; }

.article-cta.hip-care .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/hip-care-cta.png"); }

.article-cta.knee-care .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/knee-care-cta.jpg"); }

.article-cta.joint-pain-assessment {
  margin-bottom: 50px; }

.article-cta.joint-pain-assessment .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/joint-pain-assessment-cta.png"); }

.article-cta.hip-pain-symptoms-and-causes .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/hip-pain-symptoms-and-causes-cta.jpg"); }

.article-cta.hip-replacement-surgery .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/hip-replacement-surgery-cta.png"); }

.article-cta.knee-pain-symptoms-and-causes .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/knee-pain-symptoms-causes-cta.png"); }

.article-cta.knee-replacement-surgery .article-image {
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/cta/knee-replacement-surgery-cta.png"); }

@media screen and (min-width: 600px) {
  .sub-page {
    /* hero */ }
  .sub-page .hero {
    min-height: 275px; }
  .sub-page .hero h2 {
    font-size: 55px; }
  /* sub-page */ }

/* min width 600 */
@media screen and (min-width: 768px) {
  .sub-page {
    /* hero */ }
  .sub-page .hero {
    min-height: 325px;
    /* desktop-butons */ }
  .sub-page .hero h2 {
    font-size: 70px;
    margin-bottom: 15px; }
  .sub-page .hero .desktop-buttons {
    display: block;
    max-width: 650px;
    margin: 0 auto;
    margin-top: 50px; }
  .sub-page .hero .desktop-buttons .button {
    display: block;
    padding: 22px 10px;
    font-size: 16px; }
  .sub-page .fad-callout {
    display: flex;
    align-items: center; }
  .sub-page .doctors-list {
    float: right; }
  .sub-page .doctor-grid {
    margin-bottom: 0px; }
  .sub-page .doctor-grid img:hover {
    opacity: .9; }
  .sub-page .fad-callout {
    float: left; }
  .sub-page .fad-callout .button {
    max-width: 267px;
    font-size: 16px;
    padding: 23px 0px;
    margin-left: 0px; }
  .sub-page .fad-container .container > .row {
    display: flex;
    align-items: center;
    flex-direction: row-reverse; }
  .sub-page .guide-container .content-section {
    padding: 17px 15px; }
  .sub-page .guide-container .content-section .button {
    max-width: 267px;
    font-size: 16px;
    padding: 23px 0px;
    margin-left: 0px; }
  .sub-page .guide-container .guide-image {
    min-height: 365px;
    max-width: 350px; }
  /* sub-page */ }

/* min width 768 */
@media screen and (min-width: 992px) {
  .sub-page {
    /* guide-container */ }
  .sub-page .articles-container {
    padding: 40px 0px 20px 0px; }
  .sub-page .guide-container .guide-image {
    min-height: 455px;
    max-width: 500px;
    margin-top: 0px;
    background-position-y: 35px; }
  /* sub-page */ }

/* min-width 992px */
@media screen and (min-width: 1200px) {
  .sub-page .guide-container .content-section {
    padding-left: 15px;
    padding-right: 15px;
    padding: 56px 15px; } }

.sub-page.locations {
  background: #F4F6F7;
  padding-bottom: 80px; }

.sub-page.locations .hero {
  min-height: 317px;
  margin-bottom: 30px;
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/locations-hero.jpg");
  background-position: top; }

.sub-page.locations .row {
  margin-bottom: 0px; }

.location-block {
  background: #FFFFFF;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(28, 56, 78, 0.15);
  margin-bottom: 30px; }

.location-block .location-content {
  padding: 40px 30px; }

.location-block img {
  width: 100%;
  height: auto;
  margin-bottom: 20px; }

.location-block h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #00509D; }

.location-block p.address a {
  color: #707070 !important;
  text-decoration: none !important; }

.location-block p.sub-header {
  color: #00509D;
  font-weight: bold;
  font-size: 15px; }

.location-block a.download-map {
  font-weight: bold;
  text-decoration: none !important; }

.row.single .col-xs-12.col-sm-4 {
  margin: 0 auto;
  float: none; }

@media screen and (min-width: 1200px) {
  .sub-page.locations .hero {
    min-height: 380px; } }

/* Article detail page */
.article-detail {
  margin-top: 20px;
  margin-bottom: 30px;
  /* fad sidebar callout */
  /* form-container */ }

.article-detail img {
  width: 100%;
  height: auto; }

.article-detail .fad-sidebar-callout {
  background: #F5FBFF;
  box-shadow: 0px 4px 4px rgba(28, 56, 78, 0.15);
  padding: 30px 20px 20px 20px; }

.article-detail .fad-sidebar-callout .row {
  display: flex;
  align-items: center;
  margin-bottom: 0px; }

.article-detail .fad-sidebar-callout .icon-container {
  border: 2px solid #00509D;
  border-radius: 40px;
  height: 76px;
  width: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  transition: .2s; }

.article-detail .fad-sidebar-callout .icon-container i {
  color: #00509D;
  font-size: 33px; }

.article-detail .fad-sidebar-callout .button {
  display: block;
  width: 100%;
  max-width: 100%; }

.article-detail .form-container {
  background: #E5EFF7;
  box-shadow: 0px 4px 4px rgba(28, 56, 78, 0.15);
  padding: 20px; }

.article-detail .form-container h2 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px; }

/* article detail */
@media screen and (min-width: 768px) {
  .article-detail {
    margin-top: 30px; }
  .article-detail h2 {
    font-size: 30px; }
  .article-detail .fad-sidebar-callout {
    text-align: center;
    margin-bottom: 20px; }
  .article-detail .fad-sidebar-callout .row {
    display: block; }
  .article-detail .fad-sidebar-callout h2 {
    font-size: 20px; } }

@media screen and (min-width: 1200px) {
  .article-detail h2 {
    font-size: 30px; }
  .article-detail .fad-sidebar-callout {
    text-align: left; }
  .article-detail .fad-sidebar-callout .row {
    display: flex;
    align-items: center; }
  .article-detail .fad-sidebar-callout h2 {
    font-size: 20px;
    padding-left: 10px;
    padding-right: 8px; } }

.make-an-appointment {
  margin-top: 60px; }

.make-an-appointment .top-content-container {
  max-width: 615px;
  margin: 0 auto; }

.make-an-appointment .maa-text {
  text-align: center;
  font-size: 18px;
  line-height: 27px; }

.make-an-appointment .maa-text a {
  text-decoration: none !important; }

.make-an-appointment .maa-text.large {
  font-size: 25px;
  line-height: 34px;
  margin-bottom: 50px; }

.form-container-large {
  padding: 30px 20px;
  background: #E5EFF7;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 50px;
  box-shadow: 0px 4px 4px rgba(28, 56, 78, 0.15);
  margin-bottom: 80px; }

.form-container-large h2 {
  text-align: center;
  font-size: 30px;
  line-height: 105%;
  margin-bottom: 30px; }

.form-container-large hr {
  margin-bottom: 50px; }

@media screen and (min-width: 768px) {
  .form-container-large {
    padding: 50px 40px; } }

.free-guide .container.form {
  z-index: 99;
  position: relative;
  margin-top: -150px; }

.free-guide .top-content-container {
  padding-top: 50px;
  background: #F4F6F7;
  text-align: center; }

.free-guide .top-content-container p {
  font-size: 14px;
  color: #707070;
  line-height: 150%;
  font-weight: bold; }

.free-guide .top-content-container p.hide-mobile {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: none; }

.free-guide .top-content-container img {
  z-index: 1; }

.free-guide .form-container-large {
  margin-top: 0px;
  min-height: 500px; }

.free-guide .form-container-large .content-container {
  height: 100%;
  width: 100%; }

.free-guide .form-container-large h2 {
  text-align: center; }

@media screen and (min-width: 768px) {
  .free-guide .container.form {
    margin-top: -75px; }
  .free-guide .top-content-container {
    text-align: left;
    padding-bottom: 90px; }
  .free-guide .top-content-container .row {
    display: flex;
    align-items: center;
    flex-direction: row-reverse; }
  .free-guide .top-content-container .container {
    max-width: 900px;
    margin: 0 auto; }
  .free-guide .top-content-container .guide-pic {
    float: left !important; }
  .free-guide .top-content-container .header-content {
    float: right !important; }
  .free-guide .top-content-container h2 {
    font-size: 29px; }
  .free-guide .top-content-container p {
    font-size: 16px; }
  .free-guide .top-content-container p.hide-mobile {
    display: block; }
  .free-guide .form-container-large .content-container {
    width: 100%; } }

@media screen and (min-width: 992px) {
  .free-guide .form-container-large .content-container {
    width: 100%; } }

@media screen and (min-width: 1200px) {
  .free-guide .container.form {
    margin-top: -105px; }
  .free-guide .top-content-container {
    text-align: left;
    padding-bottom: 120px; }
  .free-guide .form-container-large .content-container {
    width: 100%; } }

/* Form styling */
main.sub-page,
main.landing-page,
#form {
  /* For IE10 */ }

main.sub-page label.control-label,
main.landing-page label.control-label,
#form label.control-label {
  font-size: 15px !important;
  color: #012E57 !important;
  margin-bottom: 5px !important;
  font-weight: bold !important; }

main.sub-page label.control-label.required::after,
main.landing-page label.control-label.required::after,
#form label.control-label.required::after {
  content: "*" !important;
  color: #D70000  !important;
  padding-left: 2px  !important; }

main.sub-page input, main.sub-page textarea, main.sub-page select,
main.landing-page input,
main.landing-page textarea,
main.landing-page select,
#form input,
#form textarea,
#form select {
  border: 1px solid rgba(102, 143, 183, 0.23) !important;
  background: #FFFFFF !important;
  border-radius: 0px !important;
  box-shadow: none !important; }

main.sub-page input, main.sub-page select,
main.landing-page input,
main.landing-page select,
#form input,
#form select {
  height: 45px !important;
  padding: 10px; }

main.sub-page input:active,
main.sub-page input:focus,
main.sub-page textarea:active,
main.sub-page textarea:focus,
main.sub-page select:active,
main.sub-page select:focus,
main.landing-page input:active,
main.landing-page input:focus,
main.landing-page textarea:active,
main.landing-page textarea:focus,
main.landing-page select:active,
main.landing-page select:focus,
#form input:active,
#form input:focus,
#form textarea:active,
#form textarea:focus,
#form select:active,
#form select:focus {
  background: rgba(255, 255, 255, 0.85) !important;
  border: 1px solid rgba(0, 80, 157, 0.3) !important;
  box-shadow: none !important; }

main.sub-page select,
main.landing-page select,
#form select {
  /* for Firefox */
  -moz-appearance: none !important;
  /* for Chrome */
  -webkit-appearance: none !important;
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/dropdown-arrow.png") !important;
  background-position-x: calc(100% - 20px) !important;
  background-position-y: 19px !important;
  background-repeat: no-repeat !important;
  padding-right: 38px; }

main.sub-page select:active,
main.sub-page select:focus,
main.landing-page select:active,
main.landing-page select:focus,
#form select:active,
#form select:focus {
  /* for Firefox */
  -moz-appearance: none !important;
  /* for Chrome */
  -webkit-appearance: none !important;
  background-image: url("/Portals/0/Skins/Ortho_Campaign/Images/dropdown-arrow.png") !important;
  background-position-x: calc(100% - 20px) !important;
  background-position-y: 19px !important;
  background-repeat: no-repeat !important; }

main.sub-page select::-ms-expand,
main.landing-page select::-ms-expand,
#form select::-ms-expand {
  display: none  !important; }

main.sub-page button.button,
main.landing-page button.button,
#form button.button {
  text-align: center  !important;
  color: #FFFFFF !important;
  background: #00509D !important;
  padding: 20px 20px !important;
  font-size: 16px !important;
  transition: .2s !important;
  display: block !important;
  max-width: 415px !important;
  margin: 0 auto !important;
  min-width: 315px !important;
  border: 0px !important;
  border-radius: 0px !important;
  text-transform: none; }

main.sub-page button.button:hover,
main.landing-page button.button:hover,
#form button.button:hover {
  opacity: .9 !important;
  transition: .2s !important; }

main.sub-page button.button.sidebar,
main.landing-page button.button.sidebar,
#form button.button.sidebar {
  min-width: 1px !important;
  width: 100%; }

main.sub-page .dropdown,
main.landing-page .dropdown,
#form .dropdown {
  margin-top: 36px !important; }

.alert.alert-info.submit-confirm {
  background: transparent !important;
  box-shadow: none !important; }

.alert.alert-info.submit-confirm h1, .alert.alert-info.submit-confirm h2, .alert.alert-info.submit-confirm h3, .alert.alert-info.submit-confirm h4 {
  color: #012E57 !important; }

.alert.alert-info.submit-confirm a {
  color: #012E57 !important; }

.alert.alert-info.submit-confirm a:hover {
  color: #00509D !important; }

footer {
  text-align: center; }

footer * {
  color: #FFFFFF; }

footer p {
  margin-bottom: 0px; }

footer .row {
  margin-bottom: 0px; }

footer .top-row {
  background: #00509D;
  padding-top: 25px;
  padding-bottom: 25px; }

footer .top-row .logo-copyright {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 25px;
  font-family: "Open Sans", sans-serif;
  max-width: 326px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: left; }

footer .top-row .logo-copyright img {
  width: 220px;
  text-align: left; }

footer .top-row .logo-copyright hr {
  border: 1px solid #FFFFFF;
  border-bottom: 0px;
  margin-top: 7px;
  margin-bottom: 7px;
  max-width: 56%;
  width: 56%; }

footer .top-row .logo-copyright a {
  font-size: 16px;
  text-transform: uppercase; }

footer .top-row .logo-copyright a:visited, footer .top-row .logo-copyright a:active {
  color: #FFFFFF; }

footer .top-row .logo-copyright a:hover,
footer .top-row .logo-copyright a:hover hr {
  opacity: .8;
  color: #FFFFFF;
  text-decoration: none !important; }

footer .top-row .footer-navigation {
  margin-top: 10px; }

footer .top-row .footer-navigation a {
  font-size: 16px;
  line-height: 22px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
  display: block;
  text-decoration: none !important; }

footer .top-row .footer-navigation a:visited, footer .top-row .footer-navigation a:active, footer .top-row .footer-navigation a:focus {
  color: #FFFFFF; }

footer .top-row .footer-navigation a:hover {
  color: #FFFFFF;
  opacity: .8; }

footer .bottom-row {
  background: #012E57;
  padding: 20px 0px; }

footer .bottom-row p {
  font-size: 12px;
  line-height: 150%; }

footer .bottom-row p a {
  color: #FFFFFF !important;
  text-decoration: none !important; }

/* footer */
@media screen and (min-width: 768px) {
  footer {
    /* top-row */ }
  footer .top-row .logo-copyright {
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 0px; }
  footer .top-row .logo-copyright p {
    text-align: left; }
  footer .top-row .footer-navigation {
    text-align: left; }
  footer .bottom-row p br {
    display: none; }
  /* footer */ }

/* min width 768px */
.flex-container .logoimage {
  flex-shrink: 0;
  max-height: 120px;
  margin-top: 20px;
  max-width: 180px; }

/* locations */
.location {
  padding: 30px 25px 10px 25px;
  background: #FFFFFF;
  max-width: 746px;
  margin: 0 auto;
  margin-bottom: 25px; }

.location .location-title {
  margin-bottom: 10px; }

.location .secondary-title {
  text-transform: uppercase;
  font-size: 12px;
  color: #1C384E; }

.location hr {
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0px;
  height: 2px;
  background: #012e57; }

.location p {
  color: #707070;
  line-height: 30px; }
